import {createRouter, createWebHistory} from 'vue-router';

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    scrollBehavior(to) {
        if (to.hash) {
            // if hash passed (#id)
            return {
                // scroll to id hash area
                el: to.hash,
            }
        }
        else {
            // scroll to top
            return { top: 0 }
        }
    },
    routes: [

        // Internet site open pages
        // ----------------------------
        {
            path: '/',
            name: 'home',
            component: loadView('site/Home'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/services/y-sante',
            name: 'y-sante',
            component: loadView('site/Y-sante'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/services/y-pedagogie',
            name: 'y-pedagogie',
            component: loadView('site/Y-pedagogie'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/services/y-sport',
            name: 'y-sport',
            component: loadView('site/Y-sport'),
            meta: {
                auth: undefined
            }
        },

        {
            path: '/prices',
            name: 'prices',
            component: loadView('site/Prices'),
            meta: {
                auth: undefined
            }
        },

        {
            path: '/contact',
            name: 'contact',
            component: loadView('site/Contact'),
            meta: {
                auth: undefined
            }
        },

        {
            path: '/about',
            name: 'about',
            component: loadView('site/About'),
            meta: {
                auth: undefined
            }
        },


        // Autentification and register pages
        // ----------------------------
        {
            path: '/login',
            name: 'auth-login',
            component: loadView('auth/Login'),
            meta: {
                auth: false
            }
        },
        {
            path: '/register',
            name: 'auth-register',
            component: loadView('auth/Register'),
            meta: {
                auth: false
            }
        },


        // Logged user's pages
        // ----------------------------
        {
            path: '/user',
            component: loadView('user/Index'),
            meta: {
                auth: true
            },
            children: [
                {
                    path: '/',
                    name: 'user-landing',
                    redirect: {
                        name: 'user-account'
                    }
                }, {
                    path: 'account',
                    name: 'user-account',
                    component: loadView('user/Account')
                }, {
                    path: 'unimpersonate',
                    name: 'user-unimpersonate',
                    component: loadView('user/Unimpersonate')
                }, {
                    path: 'users',
                    name: 'user-users',
                    component: loadView('user/Users')
                }, {
                    path: 'logout',
                    name: 'user-logout',
                    component: loadView('user/Logout')
                }
            ]
        },


        // Logged admin pages
        // ----------------------------
        {
            path: '/admin',
            component: loadView('admin/Index'),
            meta: {
                //auth: 'admin'
                auth: {
                    roles: 3,
                    //roles: 'admin',
                    redirect: {name: 'auth-login'},
                    notFoundRedirect: {name: 'error-404'},
                /*
                    forbiddenRedirect: function (transition) {
                        return '/admin/403';
                    }
                */
                }
            },
            children: [
                {
                    path: '/',
                    name: 'admin-landing',
                    redirect: {
                        name: 'admin-users'
                    }
                }, {
                    path: 'users',
                    name: 'admin-users',
                    component: loadView('admin/Users')
                }
            ]
        },
    ]
});

export default (app) => {
    app.router = router;

    app.use(router);
}
