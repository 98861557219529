<template>
    <div id="nav" v-bind:class="{ home: $route.name == 'home' }">
        <a href="#" class="menu-mobile"><i class="fas fa-bars"></i></a>
        <div class="container">
            <ul>
                <!-- ****************************************** -->
                <!--        Fixed navigation routes             -->
                <li
                    v-for="(route, key) in routes.fixed"
                    v-bind:key="route.path"
                >
                    <router-link
                        v-if="route.path"
                        :to="{ name : route.path }"
                        :key="key"
                    >{{ route.name }}</router-link>

                    <a :href="route.anchor" @click="goToAnchor" v-if="!route.path">{{ route.name }}</a>
                    <ul class="sub" v-if="!route.path">
                        <li
                            v-for="(subroute, key) in route.content"
                            v-bind:key="subroute.path"
                        >
                            <router-link
                                v-if="subroute.path"
                                :to="{ name : subroute.path }"
                                :key="key"
                            >{{ subroute.name }}</router-link>
                        </li>
                    </ul>
                </li>

                <!-- ****************************************** -->
                <!--    Routes shown when unlogged              -->
                <span v-if="!$auth.check()">
                    <li
                        v-for="(route, key) in routes.unlogged"
                        v-bind:key="route.path"
                    >
                        <router-link
                            :to="{ name : route.path }"
                            :key="key"
                        >{{ route.name }}</router-link>
                    </li>
                </span>

                <!-- ****************************************** -->
                <!--    Routes for logged admin                 -->
            <!--
                <span v-if="$auth.check(3)">
                    <li v-for="(route, key) in routes.admin" v-bind:key="route.path">
                        <router-link  :to="{ name : route.path }" :key="key">{{ route.name }}</router-link>
                    </li>
                </span>
            -->

                <!-- ****************************************** -->
                <!--    Routes shown unlogged                   -->
                <span v-if="$auth.check() && $auth.ready()">
                    <li>
                        <a href="#" @click.prevent="logout()">Logout</a>
                    </li>
                </span>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routes: {
                // fixed
                fixed: [
                    { name: 'Home', path: 'home' },
                    { name: 'Prestations', anchor: '#three', content: [
                        { name: 'Y-Santé', path: 'y-sante', id: 'y-sante' },
                        { name: 'Y-Pédagogie', path: 'y-pedagogie', id: 'y-pedagogie' },
                        { name: 'Y-Sport', path: 'y-sport', id: 'y-sport' }
                    ]},
                    { name: 'Tarifs', path: 'prices' },
                    { name: 'Contact', path: 'contact' }
                ],
                unlogged: [
                ],
                logged: [
                ],
                admin: [
                ],
            }
        }

    },

    methods: {
        logout() {
            this.$auth.logout({
                redirect: {name : 'home'}
            });
        },

        goToAnchor(event) {
          event.preventDefault();
          var anchor = event.target.attributes.href.value

          if (document.querySelectorAll(anchor).length > 0) {
            document.querySelector(anchor).scrollIntoView();
          }
        }
    },

    mounted() {
        window.onscroll = function() {
            var navbar = document.querySelector('#nav')

            // pageYOffset or scrollY
            if (window.pageYOffset > 50) {
                navbar.classList.add('scrolled')
            } else {
                navbar.classList.remove('scrolled')
            }
        }
    }
}

</script>
